<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <div v-if="invoice !== null && action !== null">
      <CAlert v-if="error !== null" color="danger" class="mb-3">
        {{ error }}
      </CAlert>

      <div
        v-if="
          action === INVOICE_ACTIONS.CANCEL_INVOICE ||
          action === INVOICE_ACTIONS.CANCEL_CASHED
        "
      >
        <div class="ui-type-subheading text-uppercase text-gray-600">
          {{ $t("role.finance.invoiceConfirmModal.recipient") }}
        </div>
        <h4>{{ invoiceDestinatary() }}</h4>
      </div>

      <div v-if="action === INVOICE_ACTIONS.CANCEL_PAID">
        <div class="ui-type-subheading text-uppercase text-gray-600">
          {{ $t("role.finance.invoiceConfirmModal.emitter") }}
        </div>
        <h4>{{ invoiceEmitter() }}</h4>
      </div>

      <br />

      <div class="ui-type-subheading text-uppercase text-gray-600">
        {{ $t("role.finance.invoiceConfirmModal.amount") }}
      </div>
      <h4>{{ invoiceAmount() }}</h4>
      <br />

      <p>{{ $t("role.finance.invoiceConfirmModal.alert") }}</p>
    </div>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close">{{
        $t("role.finance.invoiceEditModal.cancelBtn")
      }}</CButton>
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >{{ $t("role.finance.invoiceEditModal.confirmBtn") }}</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import EventBus from "../../../../helpers/EventBus";
import { formatPrice } from "../../../../helpers/common";
import { INVOICE_ACTIONS } from "../../../../config/global";

export default {
  name: "InvoicesPaidModal",

  data() {
    return {
      INVOICE_ACTIONS,
      loading: false,
      error: null,
      form: {
        data_payed: new Date(),
      },
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.reset();
      }
    },
  },

  computed: {
    title() {
      if (this.action === INVOICE_ACTIONS.CANCEL_PAID) {
        return this.$t("role.finance.invoiceConfirmModal.titleCancelPayment");
      }

      if (this.action === INVOICE_ACTIONS.CANCEL_INVOICE) {
        return this.$t("role.finance.invoiceConfirmModal.titleCancelInvoice");
      }

      if (this.action === INVOICE_ACTIONS.CANCEL_CASHED) {
        return this.$t("role.finance.invoiceConfirmModal.titleCancelIncasso");
      }

      return "";
    },

    show() {
      return this.$store.state.invoices.confirm.show;
    },

    invoice() {
      return this.$store.state.invoices.confirm.invoice;
    },

    action() {
      return this.$store.state.invoices.confirm.action;
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
    },

    close() {
      this.$store.commit("invoices/closeConfirmModal");
    },

    invoiceDestinatary() {
      return this.invoice.i_to.name;
    },

    invoiceEmitter() {
      return this.invoice.i_from.name;
    },

    invoiceAmount() {
      const currencySymbol = this.$store.getters["connections/currencySymbol"];
      return formatPrice(this.invoice.price, currencySymbol).format();
    },

    onSubmit() {
      this.error = null;
      this.loading = true;

      let actionDispatch = null;

      const roleId = this.$store.state.role.id;
      const familyId = this.$store.state.role.family_id;
      const invoiceId = this.invoice.id;

      if (this.action === INVOICE_ACTIONS.CANCEL_PAID) {
        actionDispatch = this.$store.dispatch("invoices/cancelPaidInvoice", {
          familyId,
          invoiceId,
        });
      }

      if (this.action === INVOICE_ACTIONS.CANCEL_INVOICE) {
        actionDispatch = this.$store.dispatch("invoices/cancelEmittedInvoice", {
          roleId,
          invoiceId,
        });
      }

      if (this.action === INVOICE_ACTIONS.CANCEL_CASHED) {
        const roleId = this.$store.state.role.id;
        actionDispatch = this.$store.dispatch("invoices/cancelCashInvoice", {
          roleId,
          invoiceId,
        });
      }

      if (actionDispatch) {
        actionDispatch
          .then(() => {
            this.close();
            EventBus.$emit("invoices:refresh");
          })
          .catch((error) => {
            this.error = error.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
