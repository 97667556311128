<template>
  <ul class="nav nav-tabs nav-tabs--transparent mb-3">
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView(INVOICE_STATUS.TO_BE_ISSUED) }"
        @click.prevent="onClick(INVOICE_STATUS.TO_BE_ISSUED)"
      >
        {{ $t("role.finance.invoicesOut.nav.toSend") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView(INVOICE_STATUS.ISSUED) }"
        @click.prevent="onClick(INVOICE_STATUS.ISSUED)"
      >
        {{ $t("role.finance.invoicesOut.nav.Sent") }}
      </div>
    </li>
  </ul>
</template>

<script>
import { INVOICE_STATUS } from "../../../../config/global";
export default {
  name: "InvoicesOutNav",

  props: {
    view: { type: String, required: true },
  },

  data() {
    return {
      INVOICE_STATUS,
    };
  },

  methods: {
    isView(view) {
      return this.view === view;
    },

    onClick(view) {
      this.$emit("update:view", view);
    },
  },
};
</script>
