<template>
  <form @submit.prevent="onSearch">
    <CRow class="align-items-end">
      <CCol md="5">
        <CInput
          :label="$t('role.finance.invoicesOut.filters.search.label')"
          :value.sync="filters.search"
          :placeholder="
            $t('role.finance.invoicesOut.filters.search.placeholder')
          "
        >
          <template #prepend-content>
            <font-awesome-icon icon="search" />
          </template>
        </CInput>
      </CCol>
      <CCol md="3">
        <CSelect
          :label="$t('role.finance.invoicesOut.filters.search.type.label')"
          :value.sync="filters.invoiceType"
          :options="invoiceTypeOptions"
          custom
        />
      </CCol>
      <!-- <CCol col="auto">
        <CButton class="mb-3" color="primary" type="submit">
          <font-awesome-icon icon="search" />
        </CButton>
      </CCol> -->
    </CRow>
  </form>
</template>

<script>
import { getInvoiceTypeOptions } from "../../../../helpers/options";
export default {
  name: "InvoicesOutFilters",

  props: {
    filters: {
      type: Object,
      required: true,
    },
  },

  data() {
    const i18n = this.$i18n;
    const options = [
      {
        value: "",
        label: this.$t(
          "role.finance.invoicesOut.filters.search.type.dropdown.placeholder"
        ),
      },
      ...getInvoiceTypeOptions(i18n),
    ];
    return {
      invoiceTypeOptions: options,
    };
  },

  methods: {
    onSearch() {
      this.$emit("onSearch", { ...this.filters });
    },
  },
};
</script>
